<div class="toolbar">
    <span i18n>
        <a routerLink="/">Baby Log</a>
        <button [matMenuTriggerFor]="types">
            <svg style="width: 24px; height: 24px;" viewBox="0 0 24 24">
                <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
            </svg>
        </button>
        <mat-menu #types="matMenu">
            <a mat-menu-item routerLink="/types">
                <span>Manage Types</span>
            </a>
            <a mat-menu-item [matMenuTriggerFor]="children">
                <span>Switch Child</span>
            </a>
            <a mat-menu-item routerLink="/family">
                <span>Manage Family</span>
            </a>
            <a mat-menu-item  [matMenuTriggerFor]="childrenHistory">
                <span>History</span>
            </a>

        </mat-menu>

        <mat-menu #children="matMenu">
            <a *ngFor="let child of userData.connectedFamilies" mat-menu-item (click)="switchTo(child)">{{
                child.name
            }}</a>
        </mat-menu>

        <mat-menu #childrenHistory="matMenu">
            <a *ngFor="let child of userData.connectedFamilies" mat-menu-item (click)="showHistoryOf(child)">{{
                child.name
            }}</a>
        </mat-menu>
    </span>
    <div class="user-details">
        <img *ngIf="auth.state | async as state" class="user-img" [src]="state.photoURL" />
        <button (click)="auth.logout()" i18n>Logout</button>
    </div>
</div>
<div style="height: 5px;">
    <progress *ngIf="userData.status !== 'ready'"></progress>
</div>
<ng-container *ngIf="auth.state | async as state; else login">
    <router-outlet></router-outlet>
</ng-container>

<ng-template #login>
    <div class="main">
        <h1 i18n>Login to use the baby log</h1>
        <button (click)="auth.login()" i18n>Login</button>
    </div>
</ng-template>
